import React, { useState } from "react";

import * as emailjs from "emailjs-com";
import { useForm } from "react-hook-form";

import { FormWrapper } from "./Contact.styles";
import Popup from "../popup/Popup";

const Contact = (props) => {
  //PROPS
  const {
    isPt,
    data: { inputName, inputPhone, inputEmail, inputSubject, inputMessage },
  } = props;
  //PROPS

  //POPSTATE
  const [response, setResponse] = useState({
    type: "",
    message: "",
  });

  const [isOpen, setisOpen] = useState(false);

  const handleClick = () => {
    setisOpen(!isOpen);
  };
  //POPSTATE

  const [info, setInfo] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const resetForm = () => {
    setInfo({
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  const handleChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
    // console.log();
  };

  const { register, errors, handleSubmit } = useForm();

  const onSubmit = (e) => {
    handleClick();

    //console.log(e);

    //e.preventDefault();

    setResponse({
      type: "loading",
      message: isPt ? "A enviar ..." : "Enviando ...",
    });

    let templateParams = {
      email: info.email,
      name: info.name,
      it_name: info.name,
      phone: info.phone,
      to_name: "",
      subject: info.subject,
      message_html: info.message,
    };

    //console.log(templateParams);

    emailjs
      .send(
        "service_b5kbk5p",
        "template_no01fzf",
        templateParams,
        "user_Se6MAiVFvKU4dAJitUHKI"
      )
      .then(
        (e) => {
          //   console.log(e);
          setResponse({
            type: "success",
            message: isPt
              ? "Obrigado pelo seu contacto. Vamos tentar responder assim que possível."
              : "Gracias por su contacto. Vamos a tratar de responder lo más pronto posible.",
          });
          resetForm();
        },
        (err) => {
          alert(JSON.stringify(err));
          setResponse({
            type: "error",
            message: isPt
              ? "Algo de errado aconteceu. Tenta outra vez por favor. Senão manda-nos diretamente um email."
              : "Algo salió mal. Inténtalo de nuevo. Si no es así, envíenos un correo electrónico directamente.",
          });
        }
      );

    resetForm();
  };

  return (
    <>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row form-row--half">
          <input
            id="name"
            name="name"
            onChange={(e) => handleChange(e)}
            placeholder={inputName + " *"}
            type="text"
            ref={register({ required: true })}
          />
          {errors.name && (
            <p>{isPt ? "Precisamos do seu nome." : "Necesitamos tu nombre."}</p>
          )}
        </div>
        <div className="form-row form-row--half">
          <input
            id="phone"
            name="phone"
            value={info.phone}
            onChange={(e) => handleChange(e)}
            placeholder={inputPhone + " *"}
            type="text"
            ref={register({ required: true })}
          />
          {errors.phone && (
            <p>
              {isPt
                ? "Precisamos do seu contacto telefónico."
                : "Necesitamos su contacto telefónico."}
            </p>
          )}
        </div>
        <div className="form-row ">
          <input
            id="email"
            name="email"
            value={info.email}
            onChange={(e) => handleChange(e)}
            placeholder={inputEmail + " *"}
            type="text"
            ref={register({ required: true })}
          />
          {errors.email && (
            <p>
              {isPt
                ? "Precisamos do seu email."
                : "Necesitamos su correo electrónico."}
            </p>
          )}
        </div>
        <div className="form-row ">
          <input
            id="subject"
            name="subject"
            value={info.subject}
            type="text"
            ref={register({ required: true })}
            onChange={(e) => handleChange(e)}
            placeholder={inputSubject + " *"}
          />
          {errors.subject && (
            <p>{isPt ? "Precisamos de um assunto." : "Necesitamos un tema."}</p>
          )}
        </div>
        <div className="form-row ">
          <textarea
            id="message"
            name="message"
            value={info.message}
            type="text"
            ref={register({ required: true })}
            onChange={(e) => handleChange(e)}
            placeholder={inputMessage + " *"}
          />
          {errors.message && (
            <p>
              {isPt ? "Precisamos de uma mensagem." : "Necesitamos un mensaje."}
            </p>
          )}
        </div>

        <button>{isPt ? "Submeter" : "Entregar"}</button>
      </FormWrapper>
      <Popup
        show={isOpen}
        toggle={() => setisOpen()}
        type={response.type}
        message={response.message}
      />
    </>
  );
};

export default Contact;
