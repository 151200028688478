import styled from "styled-components";

import { white, blue, brown } from "theme/colors";

export const FormWrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  p {
    color: #d9534f;
  }
  .form-row {
    display: block;
    padding-top: 40px;
    width: 100%;
    label {
      display: block;
    }
    input,
    textarea {
      font-size: 16px;
      width: 100%;
      display: block;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 10px;
      border-bottom: 1px solid ${brown.default};
    }
    &--half {
      width: 45%;
    }
  }
  button {
    margin-top: 40px;
    padding: 14px 28px;
    background-color: ${blue.default};
    color: ${white.default};
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
  }
`;
